* {
    margin: 0px;
    padding: 0px;
    font-family: 'Asap', sans-serif;
}

@media only screen and (min-width: 801px){
    .header{
        width: 100%;
        height: 600px;
        max-width: 100%;
        display: flex;
    }

    .background{
        width: 70%;
        height: 100%;
        background-size:cover;
        padding-top: 10px;
    }
    
    .nav{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    
    .name{
        color: white;
        font-size: 30pt;
        font-weight: 700;
    }
    
    .links{
        display: flex;
        color: white;
        font-size: 15pt;
        gap: 60px;
    }
    
    .link{
        text-decoration: none;
        color: white;
    }
    
    .info{
        width: 30%;
        height: 101.6%;
        background-color: #AFA394;
    }
    
    .portrait{
        border-radius: 50%;
        height: 200px;
        border: 2px solid lightgray;
        margin-top: 60px;
    }
    
    .socials{
        display: flex;
        justify-content: center;
        gap: 30px;
        margin-top: 30px;
    }
    
    .linkedin{
        width: 50px;
    }
    
    .github{
        width: 43px;
    }
    
    .bio{
        padding: 40px 25px;
        font-size: 15pt;
    }
    
    .projectOpening{
        margin-top: 50px;
        font-size: 30pt;
    }
    
    .allProjects{
        display: flex;
        justify-content: space-evenly;
        margin: 100px 0px;
    }
    
    .onePhoto{
        height: 350px;
        transition: transform .5s;
    }
    
    .onePhoto:hover {
        -ms-transform: scale(1.5); /* IE 9 */
        -webkit-transform: scale(1.5); /* Safari 3-8 */
        transform: scale(1.5); 
        transition: transform .5s;
    }
    
    .projectsInfo{
        padding: 20px 200px;
    }
    
    .projectTitle{
        margin: 15px 0;
    }
    
    .opportunities{
        background-color: #AFA394;
        height: 300px;
        padding-left: 20%;
        text-align: start;
    }
    
    .smallBox{
        width: 80%;
        padding-top: 90px;
    }
    
    .contact{
        height: 190px;
        padding-left: 20%;
        text-align: end;
    }
    
    .smallBox2{
        width: 80%;
        padding-top: 40px;
    }
}

@media only screen and (max-width: 800px) {
    .header{
        width: 100%;
        height: 700px;
        max-width: 100%;
        display: flex;
        flex-direction: column;
    }

    .background{
        height: 200px;
        background-size: cover;
        padding-top: 10px;
    }
    
    .nav{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    
    .name{
        color: white;
        font-size: 32pt;
        font-weight: 700;
        margin-left: 17%;
        margin-top: 30px;
    }
    
    .links{
        display: flex;
        color: white;
        font-size: 15pt;
        gap: 60px;
    }
    
    .link{
        display: none;
    }
    
    .info{
        height: 800px;
        background-color: #AFA394;
        padding-bottom: 30px;
    }
    
    .portrait{
        border-radius: 50%;
        height: 200px;
        border: 2px solid lightgray;
        margin-top: 60px;
    }
    
    .socials{
        display: flex;
        justify-content: center;
        gap: 30px;
        margin-top: 30px;
    }
    
    .linkedin{
        width: 50px;
    }
    
    .github{
        width: 43px;
    }
    
    .bio{
        padding: 40px 25px;
        font-size: 15pt;
    }
    
    .projectOpening{
        margin-top: 30px;
        font-size: 30pt;
    }
    
    .allProjects{
        display: flex;
        flex-direction: column;
        gap: 40px;
        justify-content: space-evenly;
        margin: 50px 0px;
    }
    
    .onePhoto{
        height: 350px;
        transition: transform .5s;
    }
    .onePhoto:hover {
        -ms-transform: scale(1.05); /* IE 9 */
        -webkit-transform: scale(1.05); /* Safari 3-8 */
        transform: scale(1.05); 
        transition: transform .5s;
    }
    
    .projectsInfo{
        padding: 20px 20px;
    }
    
    .projectTitle{
        margin: 15px 0;
    }
    
    .opportunities{
        background-color: #AFA394;
        height: 350px;
        padding-left: 20%;
        text-align: start;
    }
    
    .smallBox{
        width: 80%;
        padding-top: 25px;
    }
    
    .contact{
        height: 190px;
        padding-left: 20%;
        text-align: end;
    }
    
    .smallBox2{
        width: 80%;
        padding-top: 20px;
    }


}
